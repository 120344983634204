.portfolio {
	background-size: cover;
}

.portfolio__title {
	margin-bottom: 30px;
}

.content__row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	gap: 20px;
}

.card__info {
	display: none;
}

.card {
	position: relative;
	border-radius: 5px;
	border: 1px solid var(--lightgray);
	overflow: hidden;
	box-shadow: 4px 4px 4px rgba(0,0,0, .3);
	transition: box-shadow .2s ease-in;

	&:hover .card__info{
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px 20px;
		background: rgba(255, 255, 255, .95);
		box-shadow: 4px 4px 4px rgba(0,0,0, .3);
		background-image: linear-gradient(122deg,#011947, #00fefe  );
	}
}

.card__title {
	font-size: 20px;
	font-weight: 600;
	border-bottom: 2px solid var(--orange);
	width:fit-content;
	margin-bottom: 50px;
	color: #fff;
}

.card__skill {
	display: flex;
	align-items: center;
	gap: 6px;
	font-size: 17px;
	color: #fff;

	&::before {
		content: '';
		display: inline-block;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: var(--orange);
	}
}

.card__ref {
	position: absolute;
	bottom: 20px;
	right: 20px;
	display: flex;
	align-items: center;
	gap: 2px;
}
.card__ref-img {
	width: 20px;
	height: 20px;
}

