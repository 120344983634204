.skills {
	color: var(--gray);
	font-size: 20px;
	line-height: 1.5;
}

.skill-title {
	font-weight: 700;
}

.skill-name {
	margin-bottom: 30px;
}