:root {
	--container-width: 1200px;
	--container-padding: 25px;

	--font-main: 'Montserrat', sans-serif;

	--white: #fff;
	--black: #222;
	--gray: #4b5563;
	--lightgray: rgba(75, 85, 99, .5);
	--accent: blue;
	--orange: orange;
	--blue: #6d75fe;
}

.dark {
    --page-bg: #252526;
    --text-color: #fff;
}