.footer {
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
	padding: 20px 0;
	font-size: 16px;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #f00, #00f, #0f0);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #2222221a;
}
