html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  font-family: var(--font-main);
  color: var(--black);
  font-size: 15px;
  background-image: linear-gradient(122deg, #00fefe, #011947 );
  background-size: cover;
}

section {
  margin: 50px 0;
}

a {
  color: var(--black);
  font-weight: 600;
  transition: color 0.2s ease-in;
}

.title-1 {
  position: relative;
  width: fit-content;
  color: var(--black);
  font-size: clamp(22px, 1.321rem + 0.27vw, 26px);
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 30px;

  &::after {
    display: block;
    position: absolute;
    content: '';
    bottom: 11px;
    width: 75%;
    transform: translateX(10px);
    height: 8px;
    background-color: var(--orange);
    z-index: -1;
  }
}

.title-2 {
  color: var(--black);
  font-size: clamp(38px, 3.3vw, 40px);
  font-family: 'Jura', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 800;
  text-align: center;
}

.title-3i {
  color: var(--black);
  font-size: clamp(30px, 2.9vw, 35px);
  font-family: 'Jura', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 800;
  text-align: center;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  overflow: hidden;

  position: absolute;

  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);

  white-space: nowrap;
}

.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;

  border-radius: 5px;
  background-color: #30b46f;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  transition: filter 0.2s ease-in-out;
  cursor: pointer;

  font-size: 25px;
  line-height: 1.55;
  color: var(--white);

  &:hover {
    filter: contrast(150%);
  }

  &:active {
    top: 1px;
  }
}
