.header {
  padding: 20px 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.logo__img {
  font-size: 32px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #f00, #00f, #0f0);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #2222221a;
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
.logo__title {
  font-size: 22px;
  color: var(--black);
  line-height: 1.2;
  font-weight: 400;
}

.logo__desc {
  font-size: 16px;
  color: var(--gray);
  line-height: 1.2;
  white-space: nowrap;
}

.nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.nav__item {
  padding: 5px;
  font-size: 16px;
  line-height: 1.2;
  color: var(--gray);
  transition: color 0.2s ease-in;

  &:hover {
    color: var(--white);
    border-bottom: 2px solid var(--orange);
    width:fit-content;
  }
}
.social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &__icon {
    width: 32px;
    height: 32px;
  }
}

.social__icon {
  display: inline-block;
  width: 32px;
  height: 32px;
}

#git:hover,
#tel:hover {
  background-image: url('./img/tel.svg');
  transform: rotate(360deg);
  transition: transform .3s ease-in;
}

@media (max-width: 730px) {
  .nav__list,
  .social {
    display: none;
  }
}
