.mobile-nav {
  position: fixed;
  top: -100%;
  width: 100%;
  height: 100%;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: linear-gradient(122deg, #00fefe, #011947 );
  background-color: #fff;
  transition: all 0.2s ease-in;
}

.mobile-nav--open {
  top: 0;
}

.mobile-nav a {
  color: var(--white);
}

.mobile-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  font-size: 20px;
}

.mobile__logo {
	font-size: 32px;
	line-height: 2;
	margin-bottom: 50px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #f00, #00f, #0f0);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #2222221a;
}

@keyframes animate {
  0% {
    background-position: -600%;
  }
  100% {
    background-position: 600%;
  }
}
.mobile-nav__social {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
}
